
import { Component, Vue } from "vue-property-decorator";

import * as ClaimCategoryService from "@/services/ClaimCategoryService";
import { ROOT_ACTIONS } from "@/store/actions";

@Component
export default class Index extends Vue {
    newCategoryName = '';
    loading = false;

    async createNewCategory() {
        try {
            this.loading = true;
            await ClaimCategoryService.CreateClaimCategory(
                this.newCategoryName
            );
            this.newCategoryName = '';
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: "success",
                text: "Categorie aangemaakt",
            });
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: "error",
                text: error,
            });
        } finally {
            this.loading = false;
        }
    }
}

